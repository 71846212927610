import React from "react";
import Layout from "../../components/Layout";
import SingleProduct from "../../components/SingleProduct";


const Title = () => {

  const [hydrated, setHydrated] = React.useState(false);
  React.useEffect(() => {
    // This forces a rerender, so the date is rendered
    // the second time but not the first
    setHydrated(true);
  }, []);
  if (!hydrated) {
    // Returns null on first render, so the client and server match
    return null;
  }
  // useEffect(() => {
  //  if (isBrowser) {
  //           setHandle(window.location.pathname.substring(9));
  //   }
  // }, [])
       

  return (
    <Layout>
  
        <SingleProduct handle={window.location.pathname.substring(9)} />

    </Layout>
  );
};

export default Title;
