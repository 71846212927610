import { gql, useQuery } from "@apollo/client";
import React, {useState } from "react";
import AddToCart from "../components/AddToCart";
import { formatPrice } from "../utils/formatPrice";
import Loader from "../components/Loader";
import RuntimeDetailImage from "../components/RuntimeDetailImage";
import RelatedProducts from "../components/RelatedProducts";
import {Seo} from "../components/SEO";

const GET_PRODUCT = gql`
  query product ($handle: String!) {
    product(handle: $handle) {
      id
      title
      description
      productType
      vendor
      featuredImage {
        originalSrc
        width
        height
        url
      }
      tags
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }

      images(first: 250) {
        nodes {
          originalSrc
          url
          altText
          width
          height
        }
      }
      variants(first: 250) {
        nodes {
          id
          currentlyNotInStock
          quantityAvailable
          availableForSale
          title
          priceV2 {
            currencyCode
            amount
          }
          selectedOptions {
            name
            value
          }
        }
      }
      options {
        name
        values
        id
      }
    }
  }
`;

const SingleProduct = ({handle}) => {
  const [variantID, setVariantID] = useState("");
  const [variantPrice, setVariantPrice] = useState({
    currencyCode: "EUR",
    amount: 0,
  });

  const {data, loading} = useQuery(GET_PRODUCT, {
    variables: { handle: handle },
    onCompleted: (data) => {
      setAvailable(data?.product?.variants.nodes[0].availableForSale);
      setVariantID(data?.product?.variants.nodes[0].id);
      setVariantPrice({
        amount: data?.product?.variants?.nodes[0]?.priceV2?.amount,
        currencyCode: data?.product?.variants?.nodes[0]?.priceV2?.currencyCode,
      });
    },
    onError: (error) => {
      console.log(error.message);
    },
  });
  const [available, setAvailable] = useState(false);


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
        <Seo title={data?.product.title || "Product"}/>
        <section className="text-gray-700 body-font overflow-hidden bg-white">
          <div className="container px-5 py-24 mx-auto">
            <div className="lg:w-4/5 mx-auto flex flex-wrap">
             <RuntimeDetailImage
                storefrontProduct={data?.product?.featuredImage}
              />
              <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                <h2 className="text-sm title-font text-gray-500 tracking-widest">
                  Product type: {data?.product?.productType}
                </h2>
                <h2 className="text-sm title-font text-gray-500 tracking-widest">
                  Vendor: {data?.product?.vendor}
                </h2>
                <h3 className="text-gray-900 text-3xl title-font font-medium mb-1">
                  {data?.product.title}
                </h3>
                <div className="flex mb-4">
                  <span className="flex pl-3 py-2 border-l-2 border-gray-200">
                    <a
                      href="facebook.com"
                      target="_blank"
                      rel="noreferrer"
                      className="text-gray-500"
                    >
                      <svg
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                      >
                        <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                      </svg>
                    </a>
                    <a
                      href="facebook.com"
                      target="_blank"
                      rel="noreferrer"
                      className="ml-2 text-gray-500"
                    >
                      <svg
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                      >
                        <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                      </svg>
                    </a>
                    <a
                      href="facebook.com"
                      target="_blank"
                      rel="noreferrer"
                      className="ml-2 text-gray-500"
                    >
                      <svg
                        fill="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-5 h-5"
                        viewBox="0 0 24 24"
                      >
                        <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                      </svg>
                    </a>
                  </span>
                </div>
                <p className="leading-relaxed">{data?.product.description}</p>
                <div className="flex mt-6 items-center pb-5 border-b-2 border-gray-200 mb-5">
                  <div className="flex items-center">
                    <span className="mr-3">Option</span>
                    <div className="relative">
                      <select
                        onChange={(e) => {
                          setVariantID(e.target.value);
                  
                
                          setVariantPrice({
                            amount:
                              e.target.childNodes[
                                e.target.selectedIndex
                              ].getAttribute("data-price"),
                            currencyCode:
                              e.target.childNodes[
                                e.target.selectedIndex
                              ].getAttribute("data-code"),
                          });
                        }}
                        className="rounded border appearance-none border-gray-400 py-2 focus:outline-none focus:border-blue-500 text-base pl-3 pr-10"
                      >
                        {data?.product?.variants?.nodes.map(
                          ({
                            title,
                            id,
                            availableForSale,
                            priceV2,
                            quantityAvailable,
                          }) => (
                            <option
                              value={id}
                              key={id}
                              data-available={availableForSale}
                              data-quantity={quantityAvailable}
                              data-price={priceV2.amount}
                              data-code={priceV2.currencyCode}
                            >
                              {title}
                            </option>
                          )
                        )}
                      </select>
                      <span className="absolute right-0 top-0 h-full w-10 text-center text-gray-600 pointer-events-none flex items-center justify-center">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="w-4 h-4"
                          viewBox="0 0 24 24"
                        >
                          <path d="M6 9l6 6 6-6"></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between flex-wrap -mt-4">
                  <span className="mt-4 title-font font-medium text-2xl text-gray-900">
                    {formatPrice(
                      variantPrice.currencyCode,
                      variantPrice.amount
                    )}
                  </span>
              
                  <AddToCart
                    variantId={variantID}
                    available={available}
                  />
                </div>
              </div>
            </div>
          </div>
      
        </section>
        </>
      )}   
       <RelatedProducts
            productType={data?.product?.productType}
            vendor={data?.product?.vendor}
            productId={data?.product?.id}
          />
      </>
  );
};

export default SingleProduct;
